import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const BankruptcyPage = () => {
    const article = {
        id: 'e547b6ba-dbc7-5193-9f4b-3aec4d15a839',
        title: 'Understanding Bankruptcy, When It Makes Sense and How to Avoid It',
        slug: '/bankruptcy/',
        date: '2018-01-18T20:42:16.000Z',
        modified: '2021-11-01T20:29:00.000Z',
        excerpt: 'Bankruptcy can be a scary thing to think about. Whether you&#8217;re trying to avoid it, or just want to learn more about whether you should file, learn all about it here.',
        featured_image: {
            source_url: '/media/understanding-bankruptcy.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 12,
        content: (
            <>
                <p>
                    The word alone sounds like something best left to an apocalyptic novel, but the sad reality is that nearly
                    {' '}
                    <a target="_blank" href="http://www.natlbankruptcy.com/how-many-people-filed-for-bankruptcy-in-2016-2/" rel="noreferrer">800,000 people filed for bankruptcy in 2016.</a>
                </p>
                <p>While the good news is that bankruptcy filings are on the decline, the habits that put people into the situations in the first place are still there.</p>
                <p>
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=bankruptcy&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">Credit cards</a>
                    ,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=bankruptcy&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    , and many other kinds of debt weigh heavily on everyone.
                    {' '}
                </p>
                <p>
                    If you&rsquo;re considering bankruptcy, there are a few things you need to keep in mind before you file, the most important of which is that bankruptcy will affect your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=bankruptcy&amp;sub2=life" rel="noopener noreferrer" target="_blank">life</a>
                    {' '}
                    for years to come.
                    {' '}
                </p>
                <h3>Types of bankruptcy</h3>
                <p>There are two main types of personal bankruptcy: Chapter 7 and Chapter 13. </p>
                <p>Chapter 7, the most common type but also hardest to qualify for, liquidates certain assets to pay off creditors and writes off any remaining debt you are unable to pay. </p>
                <p>You are typically eligible to file Chapter 7 if your median income is below that of a family of your size in your the state. </p>
                <p>Even if it isn&rsquo;t, you may still qualify by showing that after paying for basic needs, like food and housing, you do not have enough income to pay most of your unsecured debts. </p>
                <p>
                    That includes things like credit card and medical bills and
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=bankruptcy&amp;sub2=personal-loans" rel="noopener noreferrer" target="_blank">personal loans</a>
                    {' '}
                    (this will be determined by a &quot;means test,&quot; which varies from state to state).&nbsp;
                    {' '}
                </p>
                <p>If you aren&rsquo;t eligible for Chapter 7, you may have to file Chapter 13.</p>
                <p>In the second type of bankruptcy, Chapter 13, you must follow a strict plan in which you repay some or all of your debt within a three- to five-year period. </p>
                <p>
                    <LazyLoadImage src="/media/non-business-bankruptcy-filings-1.png" alt="Non-Business Bankruptcy Filings" />
                </p>
                <p>The amount you repay is based on what you can afford. At the end of the repayment period, the remaining debt you owe may be discharged.</p>
                <p>
                    A lot of people who have fallen behind on the payments for their house or car use Chapter 13 as a way to stop a foreclosure or save their car [from repossession], says John Hargrave, the founder of bankruptcy firm&nbsp;
                    <a target="_blank" href="http://www.hargravelaw.com/" rel="noreferrer">John Hargrave &amp; Associates in Barrington, N.J.</a>
                </p>
                <h3>When to consider bankruptcy</h3>
                <p>
                    The time to consider bankruptcy is when you are so overwhelmed with debt that you are fairly certain that you won&rsquo;t be able to dig out&nbsp;
                    <strong>within five to seven years.</strong>
                </p>
                <p>When you have so many obligations that you can barely afford the interest on your loans, you might be in trouble.</p>
                <p>When you are hit with a natural disaster, medical bills, or a lost job, trying to get back on your feet is practically impossible. </p>
                <p>
                    Getting back on your feet is especially difficult if you carried an average amount of debt —
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=bankruptcy&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    , car loans, mortgage — prior to the major financial setback.
                </p>
                <p>Realistically consider your situation, especially if forces outside your control have been at work on your finances. </p>
                <p>If you can no longer afford your debt payments, bankruptcy might offer the protection and help you need to start over again.</p>
                <h2 id="understanding-the-causes-of-bankruptcy">Understanding the causes of bankruptcy</h2>
                <p>The biggest cause of personal bankruptcy in the U.S. isn&rsquo;t irresponsible spending, business failures, credit card debt, job losses or excessively big mortgages. </p>
                <p>It&rsquo;s medical bills. </p>
                <p>Millions of Americans, even if they have health insurance, are at risk of getting sick or getting in an accident and having to repay thousands of dollars of medical debt that they cannot afford. </p>
                <p>For some, bankruptcy may be the only option, but for others, it can be avoided and medical debt can be paid.&nbsp;</p>
                <p>The following are some ways to avoid medical bankruptcy and reduce medical debt.</p>
                <h3>Consider debt settlement for unpaid medical debt</h3>
                <p>
                    If you owe money to a hospital or health care professional for services that they have already provided (a surgery, hospital stay or specialty care), consider trying to
                    {' '}
                    <a href="/debt-settlement/">negotiate with them to reduce the amount of your debt</a>
                    {' '}
                    in exchange for a larger upfront payment.
                    {' '}
                </p>
                <p>There are no guarantees that a doctor or hospital will agree to reduce your debt, but it&rsquo;s worth a try. </p>
                <p>Doctor&rsquo;s offices and hospitals need to pay their bills just like any other business, and they might be willing to forgive part of your medical debt in exchange for a larger one-time payment. </p>
                <p>Whatever agreement you try to negotiate, make sure to put the details in writing and ask for written proof that the debt is marked as &quot;discharged&quot; and is not being sent to a collection agency.</p>
                <h3>Keep purchasing health insurance</h3>
                <p>Even if your budget is tight or you&rsquo;ve lost your job, don&rsquo;t let your health insurance lapse. </p>
                <p>Not having health insurance puts you at a higher risk of medical bankruptcy, since even a single car accident can put you in the hospital, or an unexpected medical diagnosis can make it hard for you to qualify for new health insurance. </p>
                <p>
                    <LazyLoadImage src="/media/americans-without-health-insurance-2.png" alt="Americans Without Health Insurance" />
                </p>
                <p>Even if you have a pre-existing condition you can get coverage from a high-risk insurance pool offered by your state government.</p>
                <p>
                    Starting in 2014, with the Affordable Care Act (&quot;Obamacare&quot;) taking effect throughout the country,&nbsp;
                    <a target="_blank" href="https://www.healthcare.gov/what-if-i-have-a-pre-existing-health-condition/" rel="noreferrer">Americans with pre-existing health conditions can get health insurance</a>
                    {' '}
                    at no added cost. But between now and then, it would be wise to keep paying for health insurance – for your own good, and your family&rsquo;s financial security.
                </p>
                <h3>Save money in an HSA or HRA</h3>
                <p>Depending on your health insurance plan, you might qualify for a Health Savings Account (HSA) or Health Reimbursement Account (HRA). </p>
                <p>With an HRA, you and your employer contribute money to a special account that can be used to pay for out of pocket health care costs, such as your annual deductible, co-pays for doctor&rsquo;s office visits, and any other costs not covered by insurance. </p>
                <p>
                    With an HSA, individuals can pay a certain amount of money each year into a special health care
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=bankruptcy&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    account that is tax-deductible and can be used to pay most out-of-pocket health care costs.
                    {' '}
                </p>
                <p>The advantage of using an HSA or HRA is that the money can be used to pay your medical bills, tax-free.</p>
                <h2 id="what-bankruptcy-cant-fix">What bankruptcy can&rsquo;t fix</h2>
                <p>There are a few debts that not even bankruptcy can touch, which means you need to be diligent in staying on top of what you owe. </p>
                <p>Here are five debts that won&rsquo;t go away even if you go bankrupt.</p>
                <h3>1. Student loans</h3>
                <p>
                    <a href="/student-loans/">Students who rack up debt with the idea that they can walk away are in for an unpleasant surprise</a>
                    .
                    {' '}
                </p>
                <p>
                    These loans remain after bankruptcy, and graduates who simply stop paying on their
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=bankruptcy&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    can find themselves ineligible for government assistance and other helpful programs.
                    {' '}
                </p>
                <p>&quot;There may be an exception if you can demonstrate hardship,&quot; says John J. Keenan, a partner at Keenan &amp; Austin, P.C. in Michigan.</p>
                <p>Most students, though, are stuck paying their loan bills.</p>
                <h3>2. Alimony and child support</h3>
                <p>Consumers who owe payments for the support of dependents are required to continue paying that obligation. </p>
                <p>Bankruptcy won&rsquo;t erase the requirement to pay alimony to a former spouse, or child support. </p>
                <p>Consumers experiencing hardship can ask a judge to modify the terms of the alimony or child support agreement, but bankruptcy doesn&rsquo;t exempt the consumer from meeting these obligations.</p>
                <h3>3. Law violations</h3>
                <p>Debt incurred due to violations of the law are not dischargeable through bankruptcy. </p>
                <p>According to Keenan, this includes traffic ticket fines, ordinance violation fines, and other fines. </p>
                <p>Additionally, debt incurred as a result of fraudulent or criminal activity can&rsquo;t be discharged through bankruptcy.</p>
                <h3>4. Recent taxes</h3>
                <p>Tax debt at the federal, state, and municipal levels, due within the last three years, can&rsquo;t be discharged through bankruptcy. </p>
                <p>With recent tax obligations, the courts encourage taxpayers to work out a payment plan to try and catch up with obligations. </p>
                <p>Keenan does say that there are some exceptions to this general rule: &quot;You may be able to discharge property taxes due on real property that you lose to foreclosure, or as a result of the bankruptcy.&quot;</p>
                <h3>5. Recently incurred debt</h3>
                <p>For the most part, bankruptcy deals with discharging debts that aren&rsquo;t likely to be paid, due to financial hardship. </p>
                <p>However, some consumers attempt to get &quot;more bang for their buck&quot; by running up debts on credit cards if they plan to file for bankruptcy. </p>
                <p>According to Keenan, this is a no-no. Large credit card purchase or cash advances incurred within 70 to 90 days of filing might not be considered dischargeable by the court.</p>
                <p>Keenan also points out that consumers need to list all of the eligible debts on the bankruptcy petition. Debt not listed won&rsquo;t be considered for discharge.</p>
                <h2 id="surviving-and-rebuilding-after-bankruptcy">Surviving and rebuilding after bankruptcy</h2>
                <p>Although bankruptcy can be a fresh start for many people, it leaves a lasting scar on their credit report. </p>
                <p>
                    It will take time to repair your credit, and in the months immediately following bankruptcy
                    {' '}
                    <a href="https://www.creditloan.com/bad-credit-loans/" target="_blank" rel="noreferrer">your credit score isn&rsquo;t going to be great</a>
                    .
                </p>
                <p>Here are three steps to rebuilding your credit after bankruptcy.</p>
                <h3>Get a new start with a secured card</h3>
                <p>In order to make that demonstration, you might have to get a secured credit card to be able to establish a payment history. </p>
                <p>
                    <LazyLoadImage src="/media/secured-credit-card-ownership-3.png" alt="Secured Credit Card Ownership" />
                </p>
                <p>That means putting money in an account in advance that would serve as your line of credit. </p>
                <p>Demonstrating responsible use of the account is one step in the repair of your credit. </p>
                <p>It likely will lead you to more traditional lending offers. </p>
                <p>Paying off balances in full before the monthly due dates will help boost your credit standing.</p>
                <h3>Pay your bills on time</h3>
                <p>From the time your bankruptcy is discharged you must stay on top of your bills. </p>
                <p>You don&rsquo;t want late payments to be noted in your credit history. </p>
                <p>You need to show that you take your financial obligations seriously and that you&rsquo;re being responsible with paying your bills.</p>
                <h3>Check your credit history</h3>
                <p>Another step, and this one doesn&rsquo;t cost a thing, is to regularly check your credit history. </p>
                <p>
                    You can get one report a year for free from each of the three largest credit reporting agencies — Experian, TransUnion and Equifax — by ordering them from the official site&nbsp;
                    <a target="_blank" href="https://www.annualcreditreport.com/cra/index.jsp" rel="noreferrer">AnnualCreditReport.com</a>
                    .
                    {' '}
                </p>
                <p>You need to make sure that everything on the reports is accurate, so you can quickly request corrections to any errors. </p>
                <p>If you&rsquo;re working hard to show you&rsquo;re on a better path, it&rsquo;s important to not let a mistake get in your way.</p>
                <p>In addition to making sure your payment history is solid, consider leaving open lines of credit that you aren&rsquo;t using — and try not to be tempted to use more than you can handle. </p>
                <p>The lower the percentage of available credit you use, the better it is for your credit score. </p>
                <p>So ditching credit cards altogether, while tempting, will not benefit your credit history or credit score.</p>
                <h2 id="protecting-your-assets-from-bankruptcy">Protecting your assets from bankruptcy</h2>
                <p>If you are considering filing for bankruptcy, your assets may be vulnerable. </p>
                <p>Assets that can&rsquo;t be touched vary by state, and often include your home and your primary vehicle for getting to work. </p>
                <p>You will have to check your state law to see what assets are protected during bankruptcy.</p>
                <p>Here are two ways to protect your money:</p>
                <h3>Put it in a retirement account</h3>
                <p>
                    In many states, the money in your&nbsp;
                    <a target="_blank" href="http://www.dol.gov/ebsa/faqs/faq_compliance_pension.html" rel="noreferrer">ERISA-qualified retirement plan</a>
&nbsp;is safe.
                    {' '}
                </p>
                <p>Not only can the money you keep in your account be protected, but your contributions may come with a tax advantage. </p>
                <p>Contribute to a retirement plan as part of your regular finances, and your money might be safe.</p>
                <h3>Transfer of assets</h3>
                <p>During bankruptcy you might be protected if the assets aren&rsquo;t&nbsp;technically&nbsp;yours. </p>
                <p>In some states, if you transfer your assets to someone else, or transfer them so that another entity — like a trust — owns them, they are protected from bankruptcy. </p>
                <p>You can transfer your assets to a business or a trust, and see a measure of protection.</p>
                <p>
                    There is a catch: you have to
                    {' '}
                    <strong>plan ahead. </strong>
                </p>
                <p>Before you increase your retirement account contributions or transfer all of your money to a trust in order to protect your assets during bankruptcy, realize that you can&rsquo;t make these moves if you are already deep in debt. </p>
                <p>If you suddenly increase your contributions, or try to transfer your assets to another person, business, or trust just before filing for bankruptcy protection, you will get into big trouble — and your assets may not be protected after all.</p>
                <p>You aren&rsquo;t supposed to use these strategies as a stop-gap to keep from paying what you owe creditors. Instead, if you want the money protected&nbsp;just in case, you need to engage in long-term financial planning well ahead of time.</p>
                <p>Before you make any moves, consult with a knowledgeable attorney, financial planner, or accountant who can help you weigh the pros and cons of your decision. </p>
                <p>You will also reduce the chance of running into pitfalls later.</p>
                <h2 id="5-signs-you-may-be-heading-for-bankruptcy">5 Signs You May Be Heading for Bankruptcy</h2>
                <p>If you&rsquo;ve read through this article and the idea of bankruptcy sounds terrifying, know that it isn&rsquo;t a given outcome.</p>
                <p>If you realize you&rsquo;re on a dangerous path, you can take steps to correct yourself and pull your finances out of danger. </p>
                <p>
                    According to the&nbsp;
                    <a target="_blank" href="http://www.aiccca.org/" rel="noreferrer">Association of Independent Consumer Credit Counseling Agencies</a>
                    , here are five warning signs of bankruptcy:
                </p>
                <h3>1. Carrying high balances on credit cards</h3>
                <p>The AICCCA recommends that people avoid carrying credit card balances that are more than 10 percent of their annual income. </p>
                <p>
                    The median American household has an annual income of&nbsp;
                    <a target="_blank" href="http://www.nytimes.com/2013/08/22/us/politics/us-median-income-rises-but-is-still-6-below-its-2007-peak.html" rel="noreferrer">$52,100 per year</a>
&nbsp;– so if you have an average income, this means that your total credit card debt should ideally be less than $5,200.
                    {' '}
                </p>
                <p>
                    <LazyLoadImage src="/media/median-household-income-2017-4.png" alt="Median Household Income (2017)" />
                </p>
                <p>Any more than that, and you are putting yourself at risk to fail to repay your credit card bills – and if your credit card debt keeps growing (with interest, fees and additional spending), it&rsquo;s going to get harder and harder to pay off.</p>
                <h3>2. Not having enough savings</h3>
                <p>How much money do you have in emergency savings? </p>
                <p>Most financial advisers recommend keeping 3-6 months&rsquo; worth of living expenses in a special, dedicated bank account. </p>
                <p>
                    Your emergency
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=bankruptcy&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    needs to be able to cover your mortgage or rent, your food and gas and energy bills, and any other everyday living expenses in case you lose your job.
                    {' '}
                </p>
                <p>If you have no savings, or have less than the recommended 3-6 months&rsquo; worth of cash in the bank, you are at greater risk of bankruptcy.</p>
                <h3>3. Buying everyday items with your credit card</h3>
                <p>Credit cards are meant to be a tool to manage your money by conveniently making payments and giving yourself a &quot;short-term loan&quot; where you can make a big purchase (car repairs, furniture, back-to-school items for your children) and then repay the debt in a short amount of time. </p>
                <p>If you&rsquo;re using your credit cards to buy everyday essentials like groceries and prescription drugs, this can be a sign that you are living dangerously beyond your means and might be at risk for bankruptcy.</p>
                <h3>4. Only paying the minimum on your credit cards</h3>
                <p>If you have $2,000 in credit card debt, you might only owe $20 or $25 per month as a &quot;minimum&quot; payment – but only making minimum payments will keep you in credit card debt for a long time. </p>
                <p>
                    If you want to get out of credit card debt,
                    {' '}
                    <a href="/credit-card-debt/">you need to pay much more than the minimum</a>
                    .
                    {' '}
                </p>
                <p>It&rsquo;s OK to carry a balance on your credit card for a few months if times are tough (you&rsquo;ll pay extra interest for the privilege), but if your money situation is so tight that you cannot afford to pay more than the minimum on your credit card, you might be heading for financial disaster.</p>
                <h3>5. You can&rsquo;t afford to lose your job</h3>
                <p>What would happen to your family if you lost your job tomorrow, or got in a car accident and were unable to work? </p>
                <p>If you have no emergency savings, if your credit cards are maxed out, if you are struggling from paycheck to paycheck, then you are vulnerable to declaring bankruptcy.</p>
                <p>Bankruptcy is not an ideal solution, but rather an option of last resort. </p>
                <p>If you can get ahead of the slide and begin repairing your finances now, you&rsquo;ll be in much better shape than if you wait until bankruptcy is the only option.</p>
                <p>This is especially true for younger people that will want to purchase a home or car in the future—bankruptcy can make that nearly impossible.</p>
                <p>Do you have any experience with bankruptcy?</p>
                <p>What did you do to avoid it, or how did you recover after declaring?</p>
                <p>Let us know in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default BankruptcyPage;
